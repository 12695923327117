import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label,
  PieChart, Pie, Cell, Legend
} from 'recharts';
import 'bootstrap/dist/css/bootstrap.min.css';

const RegistroGrafico = () => {
  const [data, setData] = useState([]);
  const userEmail = 'pruebaurquiza@gmail.com';
  const today = new Date().toISOString().split('T')[0]; // Fecha de hoy en formato YYYY-MM-DD

  // Colores para los gráficos
  const COLORS = ['#4caf50', '#ff9800', '#2196f3'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulación de datos obtenidos de una API
        const registros = [
          { date: '2024-11-20', count: 5 },
          { date: '2024-11-21', count: 10 },
          { date: '2024-11-22', count: 7 },
          { date: today, count: 1 }, // Hoy siempre tiene un registro
        ];

        setData(registros);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();
  }, [today]);

  // Datos para el gráfico de tortas - Porcentaje de postulaciones
  const postulacionesData = [
    { name: 'Postulados', value: 70 },
    { name: 'No Postulados', value: 30 },
  ];

  // Datos para el gráfico de barras - Carreras asociadas
  const carrerasData = [
    { name: 'Desarrollo de Software', count: 9 },
    { name: 'Técnico en Infraestructura', count: 1 },
    { name: 'Analista Funcional', count: 1 },
  ];

  return (
    <div
      className="full-background"
      style={{
        backgroundColor: '#1a237e',
        padding: '20px',
        width: '98%',
        position: 'relative',
      }}
    >
      {/* Botón Sticky */}
      <button
        onClick={() => window.history.back()}
        style={{
          position: 'sticky',
          top: '20px',
          left: '20px',
          backgroundColor: 'blue',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '5px',
          cursor: 'pointer',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        Volver Atrás
      </button>

      <div
        style={{
          width: '95%',
          margin: '0 auto',
          padding: '30px',
          borderRadius: '10px',
          backgroundColor: '#ffffff20',
          height: '100%',
        }}
      >
        <h2 className="text-white mb-4 text-center">Alumnos Registrados por fecha </h2>
        <div className="text-center text-white mb-3"></div>

        <ResponsiveContainer width="95%" height={400}>
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis dataKey="date" stroke="#ffffff">
              <Label value="Fecha" offset={-10} position="insideBottom" fill="#ffffff" />
            </XAxis>
            <YAxis stroke="#ffffff">
              <Label value="Cantidad" angle={-90} position="insideLeft" fill="#ffffff" />
            </YAxis>
            <Tooltip contentStyle={{ backgroundColor: '#1a237e', color: '#ffffff' }} />
            <Bar dataKey="count" fill="#5868d1" />
          </BarChart>
        </ResponsiveContainer>

        {/* Gráfico de tortas - Porcentaje de postulaciones */}
        <h3 className="text-white mt-5 mb-3 text-center">Porcentaje de Postulaciones</h3>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={postulacionesData}
              cx="50%"
              cy="50%"
              outerRadius={100}
              label={({ name }) => <text fill="#ffffff">{name}</text>}
              dataKey="value"
            >
              {postulacionesData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip contentStyle={{ backgroundColor: '#1a237e', color: '#ffffff' }} />
            <Legend
              verticalAlign="top"
              height={36}
              wrapperStyle={{ color: '#ffffff' }}
            />
          </PieChart>
        </ResponsiveContainer>

        {/* Gráfico de barras - Carreras más postuladas */}
        <h3 className="text-white mt-5 mb-3 text-center">Carreras con Más Postulaciones</h3>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart data={carrerasData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis
              dataKey="name"
              stroke="#ffffff"
              tick={{ fill: '#ffffff', fontSize: 12 }}
              interval={0}
              angle={-10}
              textAnchor="end"
            >
              <Label value="Carreras" offset={-10} position="insideBottom" fill="#ffffff" />
            </XAxis>
            <YAxis stroke="#ffffff">
              <Label value="Cantidad" angle={-90} position="insideLeft" fill="#ffffff" />
            </YAxis>
            <Tooltip
              contentStyle={{ backgroundColor: '#1a237e', color: 'white' }}
              itemStyle={{ color: 'white' }}
              labelStyle={{ color: 'white' }}
            />
            <Bar dataKey="count" fill="#4caf50" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default RegistroGrafico;
