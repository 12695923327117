import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'; 
import './Login.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Administrador = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const adminStyle = {
    color: "white",
    textAlign: "center" 
  };


  
  return (
    <div className="administrador" style={{ height: '130vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} data-aos="fade-up">
      <div className="admin" style={{ textAlign: 'center' }} data-aos="zoom-in">
        <img 
          src="/x1.png" 
          alt="Admin Logo" 
          style={{ width: '200px', height: '200px', marginBottom: '20px' }} 
          data-aos="fade-down"
        />
        <h1 style={adminStyle} data-aos="fade-right">BIENVENIDO AL ADMINISTRADOR</h1>

        <div className="btn-group-vertical" role="group" aria-label="Admin Menu" style={{ marginTop: '20px', display: 'inline-block' }}>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="100" onClick={() => navigate('/cargarempleo')}>
            CARGAR EMPLEO
          </button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="200" onClick={()=> navigate ('/CargarCategorias')}>GESTIONAR CARRERAS</button>

          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="200" onClick={() => navigate('/VerEmpleos')}>GESTIONAR EMPLEOS</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="300" onClick={()=>navigate('/MostrarUsuarios')} >GESTIONAR USUARIOS</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="500" onClick={()=>navigate('/Gestionaradm')}>GESTIONAR ADMINISTRADORES</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="500" onClick={()=>navigate('/graficos')}>VER GRÁFICOS</button>

          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="500" onClick={()=>navigate('/enviar')}>Enviar CVS</button>

          <button 
  type="button" 
  className="btn btn-primary btn-register" 
  data-aos="fade-up" 
  data-aos-delay="600" 
  onClick={() => {
    localStorage.removeItem('token'); 
    localStorage.removeItem('user');  
    navigate('/'); 
  }}
>
  SALIR
</button>

        </div>
      </div>
    </div>
  );
}

export default Administrador;
