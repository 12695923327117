import React, { useEffect, useState } from 'react';
import './MostrarEmpleos.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Modal from 'react-modal'; 
import { FaEdit, FaTrash, FaTimes,FaEnvelope  } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

Modal.setAppElement('#root');

const GestionarEmpleos = () => {
  const [empleos, setEmpleos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [empleoSeleccionado, setEmpleoSeleccionado] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({ duration: 1000 });
    cargarEmpleos();
  }, []);

  const cargarEmpleos = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Debes iniciar sesión primero.');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://apiurquiza.sistemasgenius.com/api/api/empleos', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setEmpleos(data);
      } else {
        const errorData = await response.json();
        console.error('Error al cargar empleos:', errorData);
        alert('Error al cargar los empleos');
      }
    } catch (error) {
      console.error('Error al cargar empleos:', error);
      alert('Error al cargar los empleos');
    }
  };

  const abrirModalEditar = (empleo) => {
    setEmpleoSeleccionado(empleo);
    setModalIsOpen(true);
  };

  const cerrarModal = () => {
    setModalIsOpen(false);
    setEmpleoSeleccionado(null);
  };

  const manejarEliminacion = async (empleoId) => {
    if (window.confirm('¿Estás seguro de eliminar este empleo?')) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://apiurquiza.sistemasgenius.com/api/api/empleos/${empleoId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          alert('Empleo eliminado exitosamente');
          cargarEmpleos(); 
        } else {
          alert('Error al eliminar el empleo');
        }
      } catch (error) {
        console.error('Error al eliminar el empleo:', error);
        alert('Error al eliminar el empleo');
      }
    }
  };

  const manejarEdicion = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://apiurquiza.sistemasgenius.com/api/api/empleos/${empleoSeleccionado._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(empleoSeleccionado)
      });

      if (response.ok) {
        alert('Empleo editado exitosamente');
        cargarEmpleos(); 
        cerrarModal();
      } else {
        alert('Error al editar el empleo');
      }
    } catch (error) {
      console.error('Error al editar el empleo:', error);
      alert('Error al editar el empleo');
    }
  };

  return (
<div className="gestionar-empleos" style={{ boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.2)", maxWidth: '1150px', margin: '0 auto', minHeight: '1500px' }}>
<br />
      <h2 style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1a237e', padding: '10px', borderRadius: '10px' }}>EMPLEOS</h2>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button 
          className="btn" 
          onClick={() => navigate(-1)} 
          style={{ backgroundColor: '#5868d1', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px' }}
          data-aos="zoom-in"
        >
         Atrás
        </button>
      </div>
      
      
      {empleos.map((empleo, index) => (
        <div key={index} className="empleo-card" data-aos="fade-up">
          <div className="empleo-header">
            <img 
              src={`https://apiurquiza.sistemasgenius.com/api/${empleo.imagen}`} 
              alt="Imagen del Empleo" 
              className="empleo-logo" 
              style={{ cursor: 'pointer', width: '100px', height: '100px', objectFit: 'cover', borderRadius: '10px' }}
            />
            <div className="empleo-info">
              <h3>{empleo.Puesto}</h3>
              <h3>{empleo.NombreEmpresa}</h3>
            </div>
          </div>
          <div className="empleo-body">
            <p>{empleo.anotaciones}</p>
            <small>Email de la Empresa: {empleo.email}</small>
            <br />
            <small>Publicado el: {new Date(empleo.fecha).toLocaleDateString()}</small>
          </div>
          <div className="empleo-footer">
  <button 
    className="btn btn-warning" 
    onClick={() => abrirModalEditar(empleo)} 
    style={{ marginRight: '10px', backgroundColor: '#f57c00', borderColor: '#f57c00', color: 'white' }}
  >
    <FaEdit /> Editar
  </button>
  <button 
    className="btn btn-danger" 
    onClick={() => manejarEliminacion(empleo._id)}
    style={{ marginRight: '10px', backgroundColor: '#d32f2f', borderColor: '#d32f2f', color: 'white' }}
  >
    <FaTrash /> Eliminar
  </button>
  <button 
    className="btn btn-primary" 
    onClick={() => window.alert('Se enviará un mensaje a la empresa para consultar si el empleo sigue disponible.')}
    style={{ marginLeft: '10px', backgroundColor: '#1976d2', borderColor: '#1976d2', color: 'white' }}
  >
    <FaEnvelope /> Consultar estado
  </button>
</div>

        </div>
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={cerrarModal}
        contentLabel="Editar Empleo"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '50%', 
            height: '80%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: '#1a237e',
            color: 'white',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        {empleoSeleccionado && (
          <div>
            <h2 style={{ textAlign: 'center' }}>Editar Empleo</h2>
            <div className="form-group">
              <label>Puesto:</label>
              <input 
                type="text" 
                className="form-control" 
                value={empleoSeleccionado.Puesto} 
                onChange={(e) => setEmpleoSeleccionado({ ...empleoSeleccionado, Puesto: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Nombre de Empresa:</label>
              <input 
                type="text" 
                className="form-control" 
                value={empleoSeleccionado.NombreEmpresa} 
                onChange={(e) => setEmpleoSeleccionado({ ...empleoSeleccionado, NombreEmpresa: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input 
                type="email" 
                className="form-control" 
                value={empleoSeleccionado.email} 
                onChange={(e) => setEmpleoSeleccionado({ ...empleoSeleccionado, email: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Anotaciones:</label>
              <textarea 
                className="form-control" 
                value={empleoSeleccionado.anotaciones} 
                onChange={(e) => setEmpleoSeleccionado({ ...empleoSeleccionado, anotaciones: e.target.value })}
              />
            </div>
            <button 
              className="btn btn-success" 
              onClick={manejarEdicion} 
              style={{ marginRight: '10px', backgroundColor: '#43a047', borderColor: '#43a047', color: 'white' }}
            >
              Guardar Cambios
            </button>
            <button 
              className="btn btn-secondary" 
              onClick={cerrarModal}
              style={{ backgroundColor: '#1a237e', borderColor: '#1a237e', color: 'white' }}
            >
              Cancelar
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default GestionarEmpleos;
